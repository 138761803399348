import React from "react"
import IsiNavbar from "./isinavbar"
import "bootstrap/dist/css/bootstrap.min.css"
import Container from "react-bootstrap/Container"
import "./layout.css"

const Layout = ({
	location,
	children,
	fluid,
}) =>  {
	return (
		<div className="global-wrapper" style={{ marginTop: '100px' }}>
			<header className="global-header">
				<IsiNavbar title="Institut Science Infirmière" location= {location} />
			</header>
			<main>
				<Container fluid={fluid} className="mt-5">{children}</Container>
			</main>
			<footer>
			</footer>
		</div>
	)
}

export default Layout

