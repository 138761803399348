import React, { useState } from "react"
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useStaticQuery, graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const IsiNavbar = ({ location, title }) => {
	const [active, setActive] = useState(location.pathname);
	const handleSelect = (eventKey) => {console.log(eventKey);navigate(eventKey);setActive(eventKey);};
	const data = useStaticQuery(
		graphql`
			query {
				graphCmsAProposOrdered {
					allAPropos {
						titre
						slug
					}
				}
				file(relativePath: { eq: "logo/logo.png" }) {
					childImageSharp {
						gatsbyImageData(layout: FIXED, height: 50, quality: 100)
					}
				}
			}
		`
	)
	const aProposDropdown = data.graphCmsAProposOrdered.allAPropos.map((entry) => {
		return (
		<NavDropdown.Item eventKey={"/a-propos/#" + entry.slug} key={"a-propos" + entry.slug}>
			{entry.titre}
		</NavDropdown.Item>
	)});
	return (
		<Navbar style={{ boxShadow: '0px -3px 9px grey' }} bg="light" expand="lg" fixed="top">
			<div className="container" style={{ textTransform: 'uppercase', fontSize: '14px' }}>
				<Navbar.Brand href="/"><GatsbyImage alt="logo" image={data.file.childImageSharp.gatsbyImageData} /></Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto" activeKey={active} onSelect={handleSelect}>
						<Nav.Item>
							<Nav.Link eventKey="/">
								Accueil
							</Nav.Link>
						</Nav.Item>
						<NavDropdown title="Actualités" id="basic-nav-dropdown">
							<NavDropdown.Item eventKey="/actualites/actualites">
								Actualités
							</NavDropdown.Item>
							<NavDropdown.Item eventKey="/actualites/agenda">
								Agenda
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="A propos" id="basic-nav-dropdown">
							{aProposDropdown}
						</NavDropdown>
						<Nav.Item>
							<Nav.Link eventKey="/contact">
								Contact
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</div>
		</Navbar>
	)
}

export default IsiNavbar

IsiNavbar.propTypes = {
	title: PropTypes.string.isRequired
}
